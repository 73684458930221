import React from "react"
import PropTypes from "prop-types"
import { MainLayout } from "@modules/ui/layouts/MainLayout"
import SiteCreateRoot from "../site-create-root"

const isBrowser = global.window

function SiteCreate({ location }) {
  return isBrowser ? (
    <MainLayout pathname={location.pathname}>
      <SiteCreateRoot />
    </MainLayout>
  ) : null
}

SiteCreate.propTypes = {
  location: PropTypes.object,
}

export default SiteCreate
